<template>
<vx-card no-shadow class="card-overflow">
    <ValidationObserver ref="form">
        <form-wizard color="rgba(var(--vs-primary), 1)" title="" shape="square" stepSize="lg" backButtonText="Go Back" :subtitle="null" finishButtonText="Submit" @on-complete="getPass">
            <tab-content title="Register car" icon="ti-car" size="medium" class="mb-base" :before-change="handleNext">
                <ValidationObserver ref="dataForm">
                    <form autocomplete="off" class="mt-base data-form-card">
                        <vs-row class="mb-base">
                            <vs-col vs-justify="flex-start" vs-w="8">
                                <vs-row class="mb-base">
                                    <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-w="3">
                                        <span>State of Registration:</span>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6">
                                        <ValidationProvider name="state" class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                            <vs-select v-model="selectedState" name="state" class="w-full">
                                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in stateList" />
                                            </vs-select>
                                            <!-- <flat-pickr :config="configFromdateTimePicker" v-model="fromDate" placeholder="From Date" @on-change="onFromChange" class="w-full" /> -->
                                            <span class="text-danger text-sm">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </vs-col>
                                </vs-row>
                                <vs-row class="mb-base">
                                    <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-w="3">
                                        <span>Registration Plate:</span>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6">
                                        <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="licencePlate">
                                            <vs-input class="w-full" v-model="licencePlate" />
                                            <span class="text-danger text-sm">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </vs-col>
                                </vs-row>
                                <vs-row class="mb-base">
                                    <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-w="3">
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-w="2">
                                        <vs-button class="mr-3 mb-2" @click.prevent="findCar">Find Registration</vs-button>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                            <vs-col vs-justify="flex-start" vs-w="4">
                                <vs-row class="mb-base" v-if="selectedState && licencePlate">
                                    <vx-card class="text-center bg-dark xl:w-1/3 md:w-1/2 sm:w-full">
                                        <h5 class="text-white">{{selectedState}}</h5>
                                        <h5 class="text-white">{{licencePlate}}</h5>
                                    </vx-card>
                                </vs-row>
                            </vs-col>
                        </vs-row>
                    </form>
                </ValidationObserver>
                <div v-if="isFind">
                    <vs-divider />
                    <vs-row class="mb-4">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            <vue2Dropzone @vdropzone-success="fileUploaded" @vdropzone-error="fileUploadFailed" class="w-full border-dotted" ref="myVueDropzone" id="dropzone" @vdropzone-sending="sendingEvent" :options="dropzoneOptions"></vue2Dropzone>
                        </vs-col>
                    </vs-row>
                </div>
            </tab-content>

            <tab-content title="Booking Availability" icon="ti-credit-card" class="mb-base" :before-change="findPass">
                <div>
                    <h1>Buy Pass</h1>
                </div>
            </tab-content>
        </form-wizard>
    </ValidationObserver>

</vx-card>
</template>

<script>
import AuthService from "../../services/AuthService";
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    FormWizard,
    TabContent
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import VxCard from "../../components/vx-card/VxCard.vue";
import {
    BaseConfig
} from "../../store/api/config";
import {
    carData
} from "../../store/api/car";
const authservice = new AuthService();

export default {
    props: {},
    watch: {},
    components: {
        FormWizard,
        TabContent,
        vue2Dropzone,
        VxCard
    },
    data() {
        return {
            customMessages,
            selectedState: "",
            licencePlate: "",
            isFind: false,
            stateList: [{
                    text: 'New South Wales',
                    value: 'NSW'
                },
                {
                    text: 'Queensland',
                    value: 'QLD'
                }
            ],
            fileUploadAction: "",
            dropzoneOptions: {
                url: `${BaseConfig.CCMAPI}api/CompanyCompliance/UploadComplianceDocument`,
                maxFilesize: 2560,
                thumbnailWidth: 60,
                thumbnailHeight: 60,
            }
        };
    },
    async created() {
        let token = "1111";
        let myHeaders = {
            Authorization: "Bearer " + token
        };
        this.headers = myHeaders;
        this.fileUploadAction = `${BaseConfig.CCMAPI}api/CompanyCompliance/UploadComplianceDocument`;
    },
    methods: {
        fileUploaded(file, response) {
            this.$vs.notify({
                title: "Success",
                text: "Document Uploaded",
                color: "success",
                position: "top-center"
            });
        },
        sendingEvent(file, xhr, formData) {},
        fileUploadFailed(file, message, xhr) {
            this.$vs.notify({
                title: "Failed",
                text: "Media Upload Failed",
                color: "danger",
                position: "top-center"
            });
        },
        async findCar() {
            this.isFind = true
            if (this.selectedState && this.licencePlate) {
                const request = await carData.findCar(this.licencePlate, this.selectedState);
            }
        },
        handleNext() {
            return true
        },
        async findPass() {

            if (this.selectedState && this.licencePlate) {
                const request = await carData.findCar(this.licencePlate, this.selectedState);
            }
        },
        getPass() {
        },
        goBack() {
            this.$router.back();
        }
    }
};
</script>

<style scoped>
.card-overflow {
    overflow: overlay;
}
</style>
